<template>
  <div>
    <b-card class="card-accent-primary" no-body>
      <w-top-menu :p_menuData="d_menuData"></w-top-menu>
      <b-card-header header-bg-variant="secondary" class="p-1">
        <strong v-if="user_data.first_name && user_data.last_name">
          {{ user_data.first_name + ' ' + user_data.last_name }}
        </strong>
      </b-card-header>
      <b-row v-if="d_showPage" style="">
        <b-col cols="12" v-if="!d_changePasswordScreen">
          <b-tabs>
            <b-tab active @click="SelectedNavItem = 'nav-general'; $forceUpdate()">
              <template slot="title">
                <img src="@/icon/2147852.png" style="width: 1.5em;">
                <span style="color: black">Bilgiler</span>
              </template>
            </b-tab>
            <b-tab @click="SelectedNavItem = 'nav-hims-personnel-info'; $forceUpdate()">
              <template slot="title">
                <img src="@/icon/2147852.png" style="width: 1.5em;">
                <span style="color: black">Hastane Seçimi</span>
              </template>
            </b-tab>
            <!-- <b-tab @click="SelectedNavItem = 'nav-user-wdm248-management'; $forceUpdate()">
              <template slot="title">
                <img src="@/icon/2147852.png" style="width: 1.5em;">
                <span style="color: black">Kullanıcı Yetkilendirme</span>
              </template>
            </b-tab> -->
            <!-- <b-tab v-if="user.permissions_result && user.permissions_result.indexOf('hospital-settings_users') !== -1" @click="SelectedNavItem = 'nav-clinical-branch'; $forceUpdate()">
              <template slot="title">
                <img src="@/icon/2147852.png" style="width: 1.5em;">
                <span style="color: black">Klinik Branşlar</span>
              </template>
            </b-tab> -->
            <!-- <b-tab v-if="user.permissions_result && user.permissions_result.indexOf('hospital-settings_users') !== -1" @click="SelectedNavItem = 'nav-position_department_title'; $forceUpdate()">
              <template slot="title">
                <img src="@/icon/2147852.png" style="width: 1.5em;">
                <span style="color: black">Görev & Departman & Ünvan</span>
              </template>
            </b-tab> -->
            <b-tab v-if="false && user.permissions_result && user.permissions_result.indexOf('hospital-settings_users') !== -1" @click="SelectedNavItem = 'nav-hospital_location'; $forceUpdate()">
              <template slot="title">
                <img src="@/icon/2147852.png" style="width: 1.5em;">
                <span style="color: black">Lokasyon</span>
              </template>
            </b-tab>
            <b-tab @click="SelectedNavItem = 'nav-account-list'; $forceUpdate()">
              <template slot="title">
                <img src="@/icon/2147852.png" style="width: 1.5em;">
                <span style="color: black">Hesaplar</span>
              </template>
            </b-tab>
            <!-- <b-tab v-if="user.permissions_result.indexOf('administrator') !== -1 && user.permissions_result && user.permissions_result.indexOf('hospital-settings_users') !== -1" @click="SelectedNavItem = 'nav-permission'; $forceUpdate()">
              <template slot="title">
                <img src="@/icon/2147852.png" style="width: 1.5em;">
                <span style="color: black">Yetkiler</span>
              </template>
            </b-tab> -->
            <b-tab v-if="false && user.permissions_result && user.permissions_result.indexOf('hospital-settings_users') !== -1" @click="SelectedNavItem = 'nav-permission-wisdomdatamodel'; $forceUpdate()">
              <template slot="title">
                <img src="@/icon/2147852.png" style="width: 1.5em;">
                <span style="color: black">WDM Yetkileri</span>
              </template>
            </b-tab>
            <b-tab v-if="false && user.permissions_result && user.permissions_result.indexOf('hospital-settings_users') !== -1" @click="SelectedNavItem = 'nav-permission-wisdomdatamodel_department'; $forceUpdate()">
              <template slot="title">
                <img src="@/icon/2147852.png" style="width: 1.5em;">
                <span style="color: black">WDM Departman Yetkileri</span>
              </template>
            </b-tab>
            <b-tab v-if="false" v-for="(set, set_ind) in user_settings_list" :key="'user_settings_list_' + set_ind">
              <div :class="[CalculateNavClass('nav-' + set.value)]" @click="SelectedNavItem = 'nav-' + set.value; $forceUpdate()">{{ set.label }}</div>
              <b-tab v-for="(sub_set, sub_set_ind) in set.settings" :key="'set.settings_' + sub_set_ind">
                <div :class="[CalculateNavClass('nav-' + sub_set.value)]" @click="SelectedNavItem = 'nav-' + sub_set.value; $forceUpdate()">{{ sub_set.label }}</div>
              </b-tab>
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
      <b-row v-if="d_showPage" style="">
        <b-col cols="12">
          <b-card class="card-accent-primary">
            <b-form>
              <template v-if="SelectedNavItem === 'nav-hospital_location'">
                <b-card header-tag="header" footer-tag="footer" no-body key="nav-hospital_location">
                  <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
                    Lokasyon Seçimi
                    <b-button v-if="tree_list.hospital_location.selected_items_data_list.length > 0" class="pull-right" variant="primary" size="sm" @click="fAddLocation()"><i class="fa fa-plus"></i></b-button>
                  </b-card-header>
                  <template v-if="user_data.hospital_location && user_data.hospital_location.length > 0">
                    <b-row v-for="(x, x_ind) in user_data.hospital_location" :key="'user_data.hospital_location_' + x_ind" class="form-group">
                      <b-col sm="12" lg="12">
                        <b-button @click="user_data.hospital_location.splice(x_ind, 1); $forceUpdate();" size="sm" variant="danger"><i class="fa fa-trash"></i></b-button>
                        {{ x.label }}
                      </b-col>
                    </b-row>
                  </template>
                  <template v-else>
                    <strong style="color: red;">Lokasyon bilgileri tanımlanmadı</strong>
                  </template>
                </b-card>
                <tree-list key="hospital_location" :show_mode="tree_list.hospital_location.show_mode" :tree_list_type="'hospital_location'" :select_type="tree_list.hospital_location.select_type" :mode="tree_list.hospital_location.mode" :selected_items_data_list="tree_list.hospital_location.selected_items_data_list" :max_height="tree_list.hospital_location.max_height" :location="'hospital'" :hospital_group="user_data.hospital_group" :hospital_id="user_data.hospital_id"></tree-list>
              </template>
              <!-- <template v-if="SelectedNavItem === 'nav-position_department_title'">
                <b-card header-tag="header" footer-tag="footer" no-body key="nav-position_department_title">
                  <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
                    Görev, departman ve ünvan seçimi
                    <b-button style="padding-right: 10px;" v-if="tree_list.department.selected_items_data_list.length > 0 && tree_list.position.selected_items_data_list.length > 0 && tree_list.user_title.selected_items_data_list.length > 0" class="pull-right" variant="primary" size="sm" @click="fAddPositionDepartmentTitle()"><i class="fa fa-plus"></i>Ekle</b-button>
                    <b-button style="padding-right: 10px;" v-if="tree_list.department.selected_items_data_list.length > 0 && tree_list.position.selected_items_data_list.length > 0 && tree_list.user_title.selected_items_data_list.length > 0 && selected_pdt_index" class="pull-right" variant="primary" size="sm" @click="fUpdatePositionDepartmentTitle()"><i class="fa fa-info"></i>Güncelle</b-button>
                  </b-card-header>
                  <template v-if="user_data.position_department_title && user_data.position_department_title.length > 0">
                    <b-row v-for="(x, x_ind) in user_data.position_department_title" :key="'user_data.position_department_title_' + x_ind" class="form-group">
                      <b-col sm="12" lg="1">
                        <b-form-checkbox v-model="selected_pdt_index" :value="x_ind + 1">seç</b-form-checkbox>
                      </b-col>
                      <b-col sm="12" lg="11">
                        <b-button @click="user_data.position_department_title.splice(x_ind, 1); $forceUpdate();" size="sm" variant="danger"><i class="fa fa-trash"></i></b-button>
                        <template v-if="x.position && x.position.label"> Görev: {{ x.position.label }}, </template>
                        <template v-if="x.department && x.department.label"> Departman: {{ x.department.label }}, </template>
                        <template v-if="x.department && x.department.hims_branch_id"> (HBYS branş ID: {{ x.department.hims_branch_id }}) </template>
                        <template v-if="x.title && x.title.label"> Ünvan: {{ x.title.label }} </template>
                        <b-button :variant="x.active && x.active === 1 ? 'success' : 'variant'" @click="fActivityChange(x_ind)">aktif</b-button>
                      </b-col>
                    </b-row>
                  </template>
                  <template v-else>
                    <strong style="color: red;">Henüz görev & departman & ünvan bilgileri tanımlanmadı</strong>
                  </template>
                </b-card>
                <tree-list key="department" :show_mode="tree_list.department.show_mode" :tree_list_type="'department'" :select_type="tree_list.department.select_type" :mode="tree_list.department.mode" :selected_items_data_list="tree_list.department.selected_items_data_list" :max_height="tree_list.department.max_height" :location="'hospital'" :hospital_group="user_data.hospital_group" :hospital_id="user_data.hospital_id"></tree-list>
                <tree-list key="user_title" :show_mode="tree_list.user_title.show_mode" :tree_list_type="'user_title'" :select_type="tree_list.user_title.select_type" :mode="tree_list.user_title.mode" :selected_items_data_list="tree_list.user_title.selected_items_data_list" :max_height="tree_list.user_title.max_height" :location="'hospital'" :hospital_group="user_data.hospital_group" :hospital_id="user_data.hospital_id"></tree-list>
                <tree-list key="position" :show_mode="tree_list.position.show_mode" :tree_list_type="'position'" :select_type="tree_list.position.select_type" :mode="tree_list.position.mode" :selected_items_data_list="tree_list.position.selected_items_data_list" :max_height="tree_list.position.max_height" :location="'hospital'" :hospital_group="user_data.hospital_group" :hospital_id="user_data.hospital_id"></tree-list>
              </template> -->
              <!-- <template v-if="SelectedNavItem === 'nav-clinical-branch'">
                <b-card header-tag="header" footer-tag="footer" no-body key="nav-clinical-branch">
                  <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
                    Klinik Branşları
                    <b-button class="pull-right" variant="primary" size="sm" @click="fAddClinicalBranchs()"><i class="fa fa-plus"></i></b-button>
                  </b-card-header>
                  <template v-if="user_data.clinical_branch && user_data.clinical_branch.length > 0">
                    <b-row v-for="(cli_branch, cli_branch_ind) in user_data.clinical_branch" :key="'user_data.clinical_branch_' + cli_branch_ind" class="form-group">
                      <b-col sm="12" lg="12">
                        <b-button @click="user_data.clinical_branch.splice(cli_branch_ind, 1); $forceUpdate();" size="sm" variant="danger"><i class="fa fa-trash"></i></b-button>
                        {{ cli_branch.med_branch_id }} - {{ cli_branch.label }} ({{ cli_branch.value }}) / HBYS branş ID = {{ cli_branch.hims_branch_id }}
                      </b-col>
                    </b-row>
                  </template>
                  <template v-else>
                    <strong style="color: red;">Henüz klinik branş tanımlanmadı</strong>
                  </template>
                </b-card>
                <tree-list key="clinical_branch" :show_mode="tree_list.clinical_branch.show_mode" :tree_list_type="'clinical_branch'" :select_type="tree_list.clinical_branch.select_type" :mode="tree_list.clinical_branch.mode" :selected_items_data_list="tree_list.clinical_branch.selected_items_data_list" :max_height="tree_list.clinical_branch.max_height" :location="'hospital'" :hospital_group="user_data.hospital_group" :hospital_id="user_data.hospital_id"></tree-list>
              </template> -->
              <template v-if="SelectedNavItem === 'nav-hims-personnel-info'">
                <b-form-group v-if="by_superuser" label="Hastane Grubu" label-for="hospital_group" :label-cols="3" validated>
                  <b-form-select id="hospital_group" :plain="true" @change="f_changeHospitalGroup()" v-model="user_data.hospital_group" :disabled="user && user.permissions_result.indexOf('hospital-settings_users') !== -1 ? false : true" required>
                    <option v-for="(x, x_ind) in hospital_group_data" :value="x.value">{{x.label}}</option>
                  </b-form-select>
                </b-form-group>
                <b-form-group :key="'hospital_list_' + user_data.hospital_group" v-if="by_superuser && user_data.hospital_group" label="Hastane Adı" label-for="hospital_id" :label-cols="3" validated>
                  <b-form-select id="hospital_id" :plain="true" v-model="user_data.hospital_id" :disabled="user && user.permissions_result.indexOf('hospital-settings_users') !== -1 ? false : true" required>
                    <option v-for="(x, x_ind) in selected_hospital_group.hospital_list" :value="x.value">{{x.label}}</option>
                  </b-form-select>
                </b-form-group>
                <!-- <b-form-group label="Personel ID bilgisi" :label-cols="3" label-for="user_personnel_id">
                  <template v-if="user_data.personnelId">
                    {{ user_data.personnelId }}
                  </template>
                  <template v-else>
                    <span style="color: red">Hastane personel id bilgisi henüz seçilmedi.</span>
                  </template>
                </b-form-group> -->
                <!-- <template v-if="user && user.permissions_result.indexOf('user-settings_hims-personnel-list') !== -1">
                  <b-form-group label="Tüm personelleri listele" :label-cols="3" label-for="userHimsPersonnel">
                    <template v-if="user_data.hospital_group && user_data.hospital_id">
                      <b-button @click="getAllHimsUsersByHospitalCode()">listele</b-button>
                    </template>
                    <template v-else>
                      <span style="color: red;">Lütfen hastane grubu ve hastane kodu seçimi yapınız.</span>
                    </template>
                  </b-form-group>
                  <b-form-group label="Personel Bilgisini filtrele" :label-cols="3" label-for="userHimsPersonnel">
                    <b-form-input v-model="search_text"> </b-form-input>
                  </b-form-group>
                  <div style="overflow-y: auto; height: 750px; overflow-x: hidden;">
                    <b-table selectedVariant="success" :items="himsUserListAfterSearched" :filter="search_text" :fields="himsUserListColumns">
                      <template v-slot:cell(actions)="row">
                        <b-dropdown left text="Seçenekler" variant="primary">
                          <b-dropdown-item @click="GetThisUserInformationToSelectedUser(row)">Bu kullanıcının personel bilgisini atama yap</b-dropdown-item>
                        </b-dropdown>
                      </template>
                    </b-table>
                  </div>
                </template> -->
              </template>
              <!-- <template v-if="SelectedNavItem === 'nav-user-wdm248-management'">
                <user-wdm248-management :by_superuser="by_superuser" :is_edit="is_edit" :p_grantablePermissions="d_grantablePermissions" :p_targetUser="user_data"></user-wdm248-management>
              </template> -->
              <template v-if="SelectedNavItem === 'nav-account-list'">
                <user-account-list :user="user_data"></user-account-list>
              </template>
              <template v-if="SelectedNavItem === 'nav-general'">
                <template v-if="!d_changePasswordScreen && user && user.permissions_result.indexOf('hospital-settings_users_activation') !== -1">
                  <b-form-checkbox :value="1" :unchecked-value="0" v-model="user_data.is_active">Kullanıcı Aktif</b-form-checkbox>
                  <b-form-checkbox :value="1" :unchecked-value="0" v-model="user_data.is_email_approved">Mail Onayı</b-form-checkbox>
                </template>
                <b-form-group v-if="!d_changePasswordScreen && user_data && user_data.permissions_result && user_data.permissions_result.indexOf('wisdom') !== -1" label="Kullanıcı tipi" label-for="hospital_group" :label-cols="3">
                  <strong style="color: red;">Bu kullanıcı wisdom user kullanıcısıdır.</strong>
                </b-form-group>
                <!-- <template v-if="!d_changePasswordScreen && user && user.permissions_result.indexOf('hospital-settings_users') !== -1">
                  <template v-if="by_superuser && user_data.hospital_group && user_data.hospital_group === 'MLPCARE'">
                    <b-form-group label="Hastanedeki Branş Bölümü" label-for="hospital_branch_id" :label-cols="3">
                      <template v-if="!HospitalClinicalBranchResult.status">
                        Hasta klinik branş listesi servisinden herhangi bir bilgi gelmemektedir.
                      </template>
                      <template v-else>
                        <template v-if="HospitalClinicalBranchResult.status === 'error'">
                          Hastane klinik branş listesi hatası mevcut. {{ HospitalClinicalBranchResult }}
                        </template>
                        <template v-else-if="HospitalClinicalBranchResult.status === 'success' && HospitalClinicalBranchResult.result.length === 0">
                          Hasta klinik branş listesi boş olarak gelmektedir.
                        </template>
                        <template v-else-if="HospitalClinicalBranchResult.status === 'success' && HospitalClinicalBranchResult.result.length > 0">
                          <b-form-select id="hospital_branch_id" :plain="true" v-model="user_data.hospital_branch_id">
                            <option v-for="(x, x_ind) in HospitalClinicalBranchResult.result" :value="x.id">{{ x.name }}</option>
                          </b-form-select>
                        </template>
                      </template>
                    </b-form-group>
                  </template>
                </template> -->
                <b-form-group v-if="!d_changePasswordScreen" label="Kullanıcı Adı" label-for="username" :label-cols="3">
                  <b-form-input v-if="is_edit" type="text" class="form-control-warning" id="username" :value="user_data.username" disabled></b-form-input>
                  <b-form-input v-else type="text" class="form-control-warning" id="username" :formatter="toLowerCase" v-model="user_data.username" :state="f_usernameRegexCheck()"></b-form-input>
                  <b-form-valid-feedback></b-form-valid-feedback>
                  <b-form-invalid-feedback>Kullanabileceğiniz karakterler: İngilizce harfler, sayılar, nokta işareti</b-form-invalid-feedback>
                </b-form-group>
                <b-form-group v-if="!d_changePasswordScreen" label="Ad" label-for="first_name" :label-cols="3" validated>
                  <b-form-input type="text" class="form-control-warning" id="first_name" v-model="user_data.first_name" required></b-form-input>
                  <b-form-valid-feedback></b-form-valid-feedback>
                  <b-form-invalid-feedback>Lütfen adınızı giriniz</b-form-invalid-feedback>
                </b-form-group>
                <b-form-group v-if="!d_changePasswordScreen" label="Soyad" label-for="last_name" :label-cols="3" validated>
                  <b-form-input type="text" class="form-control-warning" id="last_name" v-model="user_data.last_name" required></b-form-input>
                  <b-form-valid-feedback></b-form-valid-feedback>
                  <b-form-invalid-feedback>Lütfen soyadınızı giriniz</b-form-invalid-feedback>
                </b-form-group>
                <b-form-group v-if="!d_changePasswordScreen" label="Email" label-for="email" :label-cols="3">
                  <b-form-input type="email" class="form-control-warning" id="email" v-model="user_data.email" required :state="f_emailRegexCheck().status"></b-form-input>
                  <b-form-valid-feedback></b-form-valid-feedback>
                  <b-form-invalid-feedback>Lütfen geçerli bir email adresi giriniz</b-form-invalid-feedback>
                </b-form-group>
                <template v-if="!is_edit">
                  <b-form-group label="Şifre" label-for="password" :label-cols="3">
                    <b-form-input type="password" class="form-control-warning" id="password" v-model="user_data.password" required :state="f_checkPasswordFilled()"></b-form-input>
                    <b-form-valid-feedback></b-form-valid-feedback>
                    <b-form-invalid-feedback>Lütfen şifrenizi giriniz</b-form-invalid-feedback>
                  </b-form-group>
                  <b-form-group label="Şifre Tekrarı" label-for="password_check" :label-cols="3">
                    <b-form-input type="password" class="form-control-warning" id="password_check" :state="f_checkPasswordFilled()" v-model="passwordRepeat"></b-form-input>
                    <b-form-valid-feedback></b-form-valid-feedback>
                    <b-form-invalid-feedback>Lütfen şifrenizi tekrar giriniz</b-form-invalid-feedback>
                  </b-form-group>
                </template>
                <template v-if="is_edit">
                  <b-form-checkbox v-if="!d_changePasswordScreen" :plain="true" v-model="changePassword">Şifre Değişimi</b-form-checkbox>
                  <template v-if="changePassword">
                    <b-form-group label="Şifre" label-for="password" :label-cols="3">
                      <b-form-input type="password" class="form-control-warning" id="password" v-model="user_data.password" required :state="f_checkPasswordFilled()"></b-form-input>
                      <b-form-valid-feedback></b-form-valid-feedback>
                      <b-form-invalid-feedback>Lütfen şifrenizi giriniz</b-form-invalid-feedback>
                    </b-form-group>
                    <b-form-group label="Şifre Tekrarı" label-for="password_check" :label-cols="3">
                      <b-form-input type="password" class="form-control-warning" id="password_check" :state="f_checkPasswordFilled()" v-model="passwordRepeat"></b-form-input>
                      <b-form-valid-feedback></b-form-valid-feedback>
                      <b-form-invalid-feedback>Lütfen şifrenizi tekrar giriniz</b-form-invalid-feedback>
                    </b-form-group>
                  </template>
                </template>
                <!-- <b-form-group v-if="!d_changePasswordScreen && by_superuser" label="Bölüm Seçin" label-for="department" :label-cols="3" validated>
                  <b-form-select id="department" :plain="true" v-model="user_data.department" required>
                    <option v-for="(x, x_ind) in departments_list" :value="x.value">{{x.label}}</option>
                  </b-form-select>
                </b-form-group>
                <b-form-group v-if="!d_changePasswordScreen && by_superuser" label="Kullanıcı Grubu Seçin" label-for="group" :label-cols="3" validated>
                  <b-form-select id="group" :plain="true" v-model="user_data.group" required>
                    <option v-for="(x, x_ind) in groups_data" :value="x.value">{{x.label}}</option>
                  </b-form-select>
                </b-form-group> -->
              </template>
              <!-- <template v-if="SelectedNavItem === 'nav-permission'">
                <b-form-group label="Pozisyon Departman Ünvan Seçimi Yapınız" label-for="selected_pdt" :label-cols="3" validated>
                  <b-form-select id="selected_pdt" v-model="selected_pdt" :plain="true">
                    <option v-for="(pdt, pdt_ind) in user_data.position_department_title" :value="pdt_ind">
                      {{ pdt.title.label + '/' + pdt.department.label + '/' + pdt.position.label }} {{ pdt.active === 1 ? ' (aktif)' : '' }}
                    </option>
                  </b-form-select>
                </b-form-group>
                <b-form-group label="Yetki Şeması Seçin" label-for="permission_scheme" :label-cols="3" validated>
                  <b-input-group>
                    <b-form-select id="permission_scheme" v-model="selected_permission_scheme" :plain="true">
                      <option v-for="(scheme, scheme_ind) in permission_scheme.scheme" :value="scheme_ind">{{ scheme.label }}</option>
                    </b-form-select>
                    <b-input-group-append>
                      <b-button variant="primary" @click="select_permission_scheme('equal')"><i class="fa fa-hand-pointer-o"></i> eşitle</b-button>
                      <b-button variant="success" @click="select_permission_scheme('add_new_ones')"><i class="fa fa-plus"></i> olmayanları ekle</b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
                <b-form-group label="Yetkisi Kopyalanacak Kullanıcı Seçin" label-for="permission_from_user" :label-cols="3" validated>
                  <b-input-group>
                    <v-select style="width: 67.5%;" v-model="selected_permission_from_user" :options="user_list_with_departments"></v-select>
                    <b-input-group-append>
                      <b-button variant="primary" @click="select_permission_from_user('equal')"><i class="fa fa-hand-pointer-o"></i> eşitle</b-button>
                      <b-button variant="success" @click="select_permission_from_user('add_new_ones')"><i class="fa fa-plus"></i> olmayanları ekle</b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
                <b-form-group label="" label-for="permissions_result" :label-cols="12" v-if="selected_pdt !== '' && user_data.position_department_title && user_data.position_department_title[selected_pdt]">
                  <template v-for="(list_1, list_ind_1) in all_permissions_data.list">
                    <ul>
                      <li><b>{{ list_1.label }}</b></li>
                      <ul>
                        <template v-for="(perm_1, perm_ind_1) in list_1.permission">
                          <li>
                            <b-form-checkbox :id="perm_1.value" :value="perm_1.value" v-model="user_data.position_department_title[selected_pdt].permission">{{ perm_1.label }}</b-form-checkbox>
                          </li>
                        </template>
                        <template v-for="(list_2, list_ind_2) in list_1.list">
                          <li><b>{{ list_2.label }}</b></li>
                          <ul>
                            <template v-for="(perm_2, perm_ind_2) in list_2.permission">
                              <li>
                                <b-form-checkbox :id="perm_2.value" :value="perm_2.value" v-model="user_data.position_department_title[selected_pdt].permission">{{ perm_2.label }}</b-form-checkbox>
                              </li>
                            </template>
                            <template v-for="(list_3, list_ind_3) in list_2.list">
                              <li><b>{{ list_3.label }}</b></li>
                              <ul>
                                <template v-for="(perm_3, perm_ind_3) in list_3.permission">
                                  <li>
                                    <b-form-checkbox :id="perm_3.value" :value="perm_3.value" v-model="user_data.position_department_title[selected_pdt].permission">{{ perm_3.label }}</b-form-checkbox>
                                  </li>
                                </template>
                              </ul>
                            </template>
                          </ul>
                        </template>
                      </ul>
                    </ul>
                  </template>
                </b-form-group>
              </template> -->
              <template v-if="SelectedNavItem === 'nav-permission-wisdomdatamodel'">
                <b-form-group label="Pozisyon Departman Ünvan Seçimi Yapınız" label-for="selected_pdt" :label-cols="3" validated>
                  <b-form-select id="selected_pdt" v-model="selected_pdt" :plain="true">
                    <option v-for="(pdt, pdt_ind) in user_data.position_department_title" :value="pdt_ind">
                      {{ pdt.title.label + '/' + pdt.department.label + '/' + pdt.position.label }} {{ pdt.active === 1 ? ' (aktif)' : '' }}
                    </option>
                  </b-form-select>
                </b-form-group>
                <b-form-group label="Yetkisi Kopyalanacak Kullanıcı Seçin" label-for="permission_from_user" :label-cols="3" validated>
                  <b-input-group>
                    <v-select style="width: 67.5%;" v-model="selected_permission_from_user" :options="user_list_with_departments"></v-select>
                    <b-input-group-append>
                      <b-button variant="primary" @click="f_giveThisWisdomDataModelPermissions('equal')"><i class="fa fa-hand-pointer-o"></i> eşitle</b-button>
                      <b-button variant="primary" @click="f_giveThisWisdomDataModelPermissions('add_new_ones')"><i class="fa fa-hand-pointer-o"></i> olmayanları ekle</b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
                <template v-if="show_module_and_model && selected_pdt !== ''">
                  <tree-list :key="'module_and_model-' + selected_pdt" :show_mode="tree_list.module_and_model.show_mode" :tree_list_type="'module_and_model'" :select_type="tree_list.module_and_model.select_type" :mode="tree_list.module_and_model.mode" :selected_items_data_list="tree_list.module_and_model.selected_items_data_list" :max_height="tree_list.module_and_model.max_height" :show_items_on_top="tree_list.module_and_model.show_items_on_top" :pdt="user_data.position_department_title[selected_pdt]" :edit_user="user_data.username"></tree-list>
                </template>
              </template>
              <template v-if="SelectedNavItem === 'nav-permission-wisdomdatamodel_department'">
                <b-form-group label="Yetkisi Kopyalanacak Kullanıcı Seçin" label-for="permission_from_user" :label-cols="3" validated>
                  <b-input-group>
                    <v-select style="width: 67.5%;" v-model="selected_permission_from_user" :options="user_list_with_departments"></v-select>
                    <b-input-group-append>
                      <b-button variant="primary" @click="f_giveThisWisdomDataModelPermissions('equal')"><i class="fa fa-hand-pointer-o"></i> eşitle</b-button>
                      <b-button variant="primary" @click="f_giveThisWisdomDataModelPermissions('add_new_ones')"><i class="fa fa-hand-pointer-o"></i> olmayanları ekle</b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
                <span style="color: red;">Kullanıcı sadece aktif departmanında veri modeli ekleyebilir. Kullanıcılar burada seçilen departmanlardaki kayıtlı verileri sadece görebilirler.</span>
                <template>
                  <tree-list key="wisdom_data_model_department" :show_mode="tree_list.wdm_department.show_mode" :tree_list_type="'department'" :select_type="tree_list.wdm_department.select_type" :mode="tree_list.wdm_department.mode" :selected_items_data_list="tree_list.wdm_department.selected_items_data_list" :max_height="tree_list.wdm_department.max_height" :show_items_on_top="tree_list.wdm_department.show_items_on_top" :edit_user="user_data.username" :location="'hospital'" :hospital_group="user_data.hospital_group" :hospital_id="user_data.hospital_id"></tree-list>
                </template>
              </template>
              <template v-if="SelectedNavItem === 'nav-oncologyModule'">
                <b-form-group label="Onkoloji modülü ayarları kullanımı" label-for="settings_usage" :label-cols="3">
                  <template v-if="user_data.settings && user_data.settings.oncologyModule && user_data.settings.oncologyModule.use === '1'">
                    <b-button @click="CancelSettings('oncologyModule')" :key="'button-' + user_data.settings.oncologyModule.use">iptal et</b-button>
                  </template>
                  <template v-else>
                    <b-button @click="ActivateSettings('oncologyModule')">aktive et</b-button>
                  </template>
                </b-form-group>
              </template>
              <template v-if="SelectedNavItem === 'nav-drugApprovalDosage'">
                <b-form-group label="İlaç doz ayarları kullanımı" label-for="settings_usage" :label-cols="3">
                  <template v-if="user_data.settings && user_data.settings.oncologyModule && user_data.settings.oncologyModule.settings && user_data.settings.oncologyModule.settings.drugApprovalDosage && user_data.settings.oncologyModule.settings.drugApprovalDosage.use && user_data.settings.oncologyModule.settings.drugApprovalDosage.use === '1'">
                    <b-button @click="CancelSettings('oncologyModule', 'drugApprovalDosage')" :key="'button-' + user_data.settings.oncologyModule.settings.drugApprovalDosage.use">iptal et</b-button>
                  </template>
                  <template v-else>
                    <b-button @click="ActivateSettings('oncologyModule', 'drugApprovalDosage')">aktive et</b-button>
                  </template>
                </b-form-group>
                <b-form-group label="İlaç seçimi" label-for="SelectedDrug" :label-cols="3">
                  <v-select v-if="search_all_drugs" v-model="SelectedDrug" @search="drug_search" :options="drug_search_list"></v-select>
                  <v-select v-else v-model="SelectedDrug" :options="favorite_drugs"></v-select>
                  <b-input-group-append>
                    <b-button variant="success" @click="AddDrugToDosageSettings()"><i class="fa fa-plus"></i></b-button>
                  </b-input-group-append>
                </b-form-group>
                <template v-if="user_data.settings && user_data.settings.oncologyModule && user_data.settings.oncologyModule.settings.drugApprovalDosage">
                  <b-row v-for="(drug, drug_ind) in user_data.settings.oncologyModule.settings.drugApprovalDosage.drugs" :key="'drug' + drug.name.value">
                    <b-col sm="12" lg="3">
                      {{ drug.name.label }}
                    </b-col>
                    <b-col sm="12" lg="9">
                      <b-row>
                        <b-col sm="12" lg="3">
                          Min
                          <c-switch class="mx-1" color="success" v-model="drug.min.use" value="1" uncheckedValue="0" variant="pill" />
                        </b-col>
                        <b-col sm="12" lg="3">
                          <b-form-input v-model="drug.min.val"></b-form-input>
                        </b-col>
                        <b-col sm="12" lg="3">
                          Max
                          <c-switch class="mx-1" color="success" v-model="drug.max.use" value="1" uncheckedValue="0" variant="pill" />
                        </b-col>
                        <b-col sm="12" lg="3">
                          <b-form-input v-model="drug.max.val"></b-form-input>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </template>
              </template>
            </b-form>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
    <modal v-if="d_showProfilePictureModal" @close="d_showProfilePictureModal = false" :width="'1000'">
      <div slot="header">Kullanıcı Resmi Yükleme Ekranı</div>
      <div slot="body">
        <template v-if="d_userProfilePicture">
          <img :src="d_userProfilePicture" width="125px" />
        </template>
        <b-row>
          <b-col cols="6"><strong>Döküman ekle</strong> ({{ f_createAcceptedDocumentTypes() }})</b-col>
          <b-col cols="6">
            <b-form-file @input="f_addDocument()" v-model="d_tmpFile" :accept="f_createAcceptedDocumentTypes()" :placeholder="$t('wdm16.13222')" :drop-placeholder="$t('wdm16.13223')"></b-form-file>
          </b-col>
        </b-row>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-success" @click="f_uploadProfilePicture()">{{ $t('wdm16.4061') }}</button>
        <button type="button" class="btn btn-danger" @click="d_showProfilePictureModal = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
    <modal v-if="mother_children.d_showUserWdm248ManagementModal" @close="mother_children.d_showUserWdm248ManagementModal = false" :width="'1500'">
      <div slot="header">Yetki Düzenleme Ekranı</div>
      <div slot="body">
        <user-wdm248-management :mother_children="mother_children" :by_superuser="by_superuser" :is_edit="is_edit" :p_grantablePermissions="d_grantablePermissions" :p_userPermissions="d_userPermissions" :p_managerUserId="user.id" :p_targetUser="user_data"></user-wdm248-management>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-danger" @click="mother_children.d_showUserWdm248ManagementModal = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
    <b-modal title="Kopyalanacak Kullanıcıyı Seçin" centered class="modal-success" v-model="d_showUserCloneModal" @ok="d_showUserCloneModal = false" ok-variant="success" hide-footer>
      <b-form-group label="Kaynak Kullanıcı" label-for="permission_from_user" :label-cols="3" validated>
        <b-input-group>
          <v-select style="width: 100%;" v-model="d_userCloneSource" :options="d_availableUserListForClone"></v-select>
        </b-input-group>
      </b-form-group>
      <br>
      <b-row class="pull-right">
        <b-col cols="12">
          <b-button v-if="d_userCloneSource.value" variant="success" @click="f_cloneFromAnotherUser()">{{ $t('wdm16.4061') }}</b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  default as TreeList
} from '@/components/widgets/TreeList';
import TreeListService from '@/services/tree_list';
import {
  default as Modal
} from '@/components/widgets/Modal';
import Vue from 'vue';
import { mapGetters } from 'vuex';
// import Vue from 'vue';
// import { API_BASE_URL } from 'wisdom-config';
import GlobalService from '@/services/global';
import {
  default as router
} from '@/router';
import UserService from '@/services/users';
import PermissionService from '@/services/permission';
import PermissionShemeService from '@/services/permission_scheme';
import { WisdomRegex } from '@/services/public/functions';
import HospitalService from '@/services/hospital';
import vSelect from 'vue-select';
import {
  default as UserAccountList
} from '@/components/widgets/UserAccountList';
import {
  default as UserWdm248Management
} from '@/components/widgets/UserWdm248Management';
import { Switch as cSwitch } from '@coreui/vue'
import OptionService from '@/services/option';
import WebService from '@/services/webservice';
import {
  default as WTopMenu
} from '@/components/widgets/WTopMenu';

export default {
  name: 'UserManage',
  computed: {
    ...mapGetters({
      StoreModal: 'modal',
      patient: 'patient_selected'
    })
  },
  props: {},
  data () {
    return {
      by_superuser: false,
      username: '',
      is_edit: false,
      d_menuData: {
        'selected_menu': 'general', // your defined menu value below
        'style': {
          'backgroundColor': '#FFFFFF',
          'subBackgroundColor': '#FFFFFF',
          'subColor': '#000000',
          'color': '#000000',
          'main_header': {
            'backgroundColor': '#FFFFFF',
            'color': '#000000'
          },
          'tab_header': {
            'backgroundColor': '#FFFFFF',
            'color': '#878484'
          }
        },
        'list': [{
          'label': 'Genel',
          'icon': require('@/icon/2979152.png'),
          'value': 'general',
          'list': [{
            'icon': '',
            'label': 'Genel',
            'value': 'general',
            'list': [{
              'type': 'button', // button, buttongroup, color
              'title': 'Yetkileri Düzenleme',
              'icon': require('@/icon/1603498.png'),
              'label': '',
              'value': 'f_showUserWdm248ManagementModal',
              'click': this.f_showUserWdm248ManagementModal
            }]
          }, {
            'icon': '',
            'label': 'İşlemler',
            'value': 'operations',
            'list': [{
              'type': 'button', // button, buttongroup, color
              'title': 'Ziyaret Edilen Hastaları Temizle', // save
              'icon': require('@/icon/2032353.png'),
              'label': '',
              'value': 'f_cleanVisitedPatientList',
              'click': this.f_cleanVisitedPatientList
            }, {
              'type': 'button', // button, buttongroup, color
              'title': 'Kullanıcı Kopyala', // save
              'icon': require('@/icon/2349601.png'),
              'label': '',
              'value': 'f_showUserCloneModal',
              'click': this.f_showUserCloneModal
            }, {
              'type': 'button', // button, buttongroup, color
              'title': 'Resim Yükle', // save
              'icon': require('@/icon/2940373.png'),
              'label': '',
              'value': 'f_showProfilePictureModal',
              'click': this.f_showProfilePictureModal
            }]
          }]
        }, {
          'label': 'Reset&Kayıt',
          'icon': require('@/icon/2979152.png'),
          'value': 'reset_save',
          'list': [{
            'icon': '',
            'label': 'Genel',
            'value': 'general',
            'list': [{
              'type': 'button', // button, buttongroup, color
              'title': 'Resetle', // save
              'icon': require('@/icon/287792.png'),
              'label': '',
              'value': 'f_resetForm',
              'click': this.f_resetForm
            }, {
              'type': 'button', // button, buttongroup, color
              'title': 'Kaydet', // save
              'icon': require('@/icon/2087726.png'),
              'label': '',
              'value': 'f_saveUser',
              'click': this.f_saveUser
            }]
          }]
        }, {
          'label': 'Yardım',
          'icon': require('@/icon/2249810.png'),
          'value': 'help',
          'list': [{
            'icon': require('@/icon/2249810.png'),
            'label': 'Genel',
            'value': 'general',
            'list': []
          }]
        }]
      },
      d_showPage: true,
      show_module_and_model: true,
      tree_list: {
        'module_and_model': {
          'show_items_on_top': false,
          'max_height': '300',
          'selected_items_data_list': [],
          'mode': 'use', // ['edit', 'use']
          'show_mode': 'treeView',
          'select_type': 'multi' // ['one', 'multi']
        },
        'position': {
          'max_height': '300',
          'selected_items_data_list': [],
          'mode': 'use', // ['edit', 'use']
          'show_mode': 'treeView',
          'select_type': 'one' // ['one', 'multi']
        },
        'hospital_location': {
          'max_height': '300',
          'selected_items_data_list': [],
          'mode': 'use', // ['edit', 'use']
          'show_mode': 'treeView',
          'select_type': 'one' // ['one', 'multi']
        },
        'clinical_branch': {
          'max_height': '300',
          'selected_items_data_list': [],
          'mode': 'use', // ['edit', 'use']
          'show_mode': 'treeView',
          'select_type': 'one' // ['one', 'multi']
        },
        'user_title': {
          'max_height': '300',
          'selected_items_data_list': [],
          'mode': 'use', // ['edit', 'use']
          'show_mode': 'treeView',
          'select_type': 'one' // ['one', 'multi']
        },
        'department': {
          'max_height': '300',
          'selected_items_data_list': [],
          'mode': 'use', // ['edit', 'use']
          'show_mode': 'treeView',
          'select_type': 'one' // ['one', 'multi']
        },
        'wdm_department': {
          'show_items_on_top': false,
          'max_height': '300',
          'selected_items_data_list': [],
          'mode': 'use', // ['edit', 'use']
          'show_mode': 'treeView',
          'select_type': 'multi' // ['one', 'multi']
        }
      },
      himsUserListColumns: [{
        key: "actions",
        label: "İşlem"
      }, {
        key: "personnel_id",
        label: "personel Id"
      }, {
        key: "resourcetype_id",
        label: "Kaynak Tipi ID"
      }, {
        key: "resource_id",
        label: "Kaynak ID"
      }, {
        key: "resource_name",
        label: "Kaynak Adı"
      }, {
        key: "username",
        label: "Kullanıcı Adı"
      }, {
        key: "group_name",
        label: "Grup Adı"
      }],
      user_data_bck: { 'permissions_result': [], 'username': '', 'is_active': 1 },
      ShowUserHimsPersonnelList: false,
      himsUserList: [],
      himsUserListAfterSearched: [],
      user_settings_list: [],
      drug_search_list: [],
      favorite_drugs: require('@/options/favorite_drugs').options,
      SelectedDrug: '',
      search_all_drugs: false,
      wisdom_user: false,
      changePassword: false,
      passwordRepeat: '',
      selected_hospital_group: '',
      permission_scheme: {},
      hospital_group_data: {},
      user_data: {
        'permissions_result': [],
        'username': '',
        'is_active': 1,
        'is_email_approved': 1,
        'wisdom_data_model': {},
        'department': 'adult_medical_oncology_physician',
        'group': 'doctor',
        'position_department_title': [
          {
            'wisdom_data_model': {},
            'permission': [],
            'title': {
              'perms': [],
              'isTitle': 'no',
              'value': 'general',
              'label': 'Genel'
            },
            'department': {
              'perms': [],
              'isTitle': 'no',
              'value': 'general',
              'label': 'Genel'
            },
            'active': 1,
            'position': {
              'perms': [],
              'isTitle': 'no',
              'value': 'general',
              'label': 'Genel'
            }
          }
        ]
      },
      user: {},
      departments_list: [{
        label: 'Erişkin tıbbi onkoloji doktoru',
        value: 'adult_medical_oncology_physician',
        translation: { tr: 'Erişkin tıbbi onkoloji doktoru', en: 'Adult medical oncology physician' }
      }, {
        label: 'Erişkin tıbbi onkoloji hemşiresi',
        value: 'adult_medical_oncology_nurse',
        translation: { tr: 'Erişkin tıbbi onkoloji hemşiresi', en: 'Adult medical oncology nurse' }
      }, {
        label: 'Erişkin tıbbi onkoloji sekreteri',
        value: 'adult_medical_oncology_secretary',
        translation: { tr: 'Erişkin tıbbi onkoloji sekreteri', en: 'Adult medical oncology secretary' }
      }, {
        label: 'Eczacı',
        value: 'pharmacist',
        translation: { tr: 'Eczacı', en: 'Pharmacist' }
      }],
      edit: false,
      groups_data: [{ 'value': 'doctor', 'label': 'Doktor', 'translation': { 'tr': 'Doktor' } }, { 'value': 'nurse', 'label': 'Nurse', 'translation': { 'tr': 'Hemşire' } }, { 'value': 'pharmacy', 'label': 'Pharmacy', 'translation': { 'tr': 'Eczane' } }, { 'value': 'secretary', 'label': 'Secretary', 'translation': { 'tr': 'Sekreter' } }, { 'value': 'superuser', 'label': 'Super User', 'translation': { 'tr': 'Yönetici' } }],
      all_permissions_data: [],
      username_err: null,
      old_username: null,
      edit_user_data: {},
      SelectedNavItem: 'nav-general',
      HospitalClinicalBranchResult: {},
      hospital_data: {},
      himsUserName: '',
      search_text: '',
      selected_pdt: '',
      selected_permission_scheme: '',
      selected_permission_from_user: '',
      d_grantablePermissions: [],
      d_userCurrentPermissions: {},
      d_userPermissions: {},
      user_list_with_departments: [],
      selected_pdt_index: '',
      mother_children: { 'd_showUserWdm248ManagementModal': false },
      d_showProfilePictureModal: false,
      d_showUserCloneModal: false,
      d_userCloneSource: {},
      d_tmpFile: '',
      d_uploadPicData: '',
      d_userProfilePicture: '',
      d_availableUserListForClone: [],
      d_formCheckList: { 'email': false, 'password': false, 'username': true },
      d_changePasswordScreen: false
    };
  },
  beforeMount: function () {
    this.all_users = JSON.parse(localStorage.getItem('all_users'));
    this.user = JSON.parse(localStorage.getItem('user'));
    this.hospital_data = JSON.parse(localStorage.getItem('hospital_data'));

    if (router.currentRoute.name === 'useredit') {
      this.is_edit = true;
      this.username = router.currentRoute.query.username;
    }

    if (this.user.permissions_result && this.user.permissions_result.indexOf('hospital-settings_users') !== -1) {
      this.by_superuser = true
    }

    this.get_all_permissions();
    this.get_permission_scheme();
    this.GetUserSettingsList();
    if (this.is_edit) {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Kullanıcı bilgisi getiriliyor. Lütfen bekleyiniz' } });
      UserService.get(this.username)
        .then(resp => {
          this.edit_user_data = resp.data;
          this.get_hospital_group();
        });
    } else {
      this.get_hospital_group();
    }
    // if (this.user_data.hospital_id && this.user_data.hospital_group) {
    //   this.GetClinicalBranchesOfHospital();
    // }
    this.f_checkPasswordFilled();
    this.f_emailRegexCheck();
    /*    let query = '';
        query += 'parent=location';
        query += '&hospital=1';
        query += '&hospitalCode=' + this.user_data.hospital_id;
        query += '&hospitalGroup=' + this.user_data.hospital_group;
        TreeListService.getTreeList(query)
          .then(resp => {
            if (resp.data.status === 'success') {
              this.tree_list_location_data = resp.data.result;
            }
          }); */
  },
  mounted: function () {
    if (this.$router.currentRoute.query.changePassword && this.is_edit) {
      this.SelectedNavItem = 'nav-general';
      this.d_changePasswordScreen = true;
      this.changePassword = true;
      this.user_data.password = '';
      this.d_formCheckList.email = true;
    }
  },
  methods: {
    f_showProfilePictureModal: function () {
      if (!this.d_changePasswordScreen && this.is_edit) {
        this.d_showProfilePictureModal = true;
      } else {
        let msg = 'Bu işlemi gerçekleştirmeye yetkiniz olmayabilir. Yetkiniz olduğundan eminseniz lütfen bu işlemi kullanıcı düzenleme ekranında kullanınız. Yeni kullanıcı açılırken ve/veya şifre düzenleme ekranında bu özellik devre dışıdır';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Profil resmi yükleme işlemi hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_showUserWdm248ManagementModal: function () {
      if (!this.d_changePasswordScreen && this.by_superuser && this.is_edit) {
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Yetkiler hazırlanıyor' } });
        let query = 'user_id=' + this.user_data.id;
        UserService.get_user_perm_list_for_edit(query)
          .then(resp => {
            this.d_userPermissions = resp.data.result;
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            this.mother_children.d_showUserWdm248ManagementModal = true;
          });
      } else {
        let msg = 'Bu işlemi gerçekleştirmeye yetkiniz olmayabilir. Yetkiniz olduğundan eminseniz lütfen bu işlemi kullanıcı düzenleme ekranında kullanınız. Yeni kullanıcı açılırken ve/veya şifre düzenleme ekranında bu özellik devre dışıdır';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Kullanıcı hesabı yetkilendirme işlemi hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_usernameRegexCheck () {
      const regex = /^[\.a-z0-9]*$/ig;
      // var regex = new RegExp("#" + stringToGoIntoTheRegex + "#", "g");
      // at this point, the line above is the same as: var regex = /#abc#/g;
      if (this.user_data.username) {
        let regexUsername = regex.exec(this.user_data.username);
        if (regexUsername && (this.user_data.username === regexUsername[0])) {
          this.d_formCheckList.username = true;
          return true;
        }
      }
      this.d_formCheckList.username = false;
      return false;
    },
    f_checkPasswordFilled: function () {
      if (!this.changePassword && this.is_edit) {
        this.d_formCheckList.password = true;
        return true;
      }
      if (this.passwordRepeat === this.user_data.password && this.passwordRepeat.length >= 8) {
        let status = WisdomRegex.password(this.user_data.password).status;
        this.d_formCheckList.password = status;
        return status;
      }
      this.d_formCheckList.password = false;
      return false;
    },
    f_emailRegexCheck: function () {
      if (!this.user_data.email) {
        this.d_formCheckList.email = false;
        return { 'value': '', 'status': false };
      } else {
        let regex_result = WisdomRegex.email(this.user_data.email);
        this.d_formCheckList.email = regex_result.status;
        return regex_result;
      }
    },
    f_cleanVisitedPatientList: function () {
      if (!this.d_changePasswordScreen && this.by_superuser && this.is_edit) {
        let modal_data = { 'type': 'confirm', 'text': 'Kullanıcının ziyaret edilen hasta listesini silmek istediğinize emin misiniz? Hasta arama sayfasında son girilen hasta listesi temizlenecektir', 'centered': true, 'title': 'Kullanıcının ziyaret edilen hasta listesini silme', 'confirm': false, 'function_name': 'f_cleanVisitedPatientListConfirmed', 'arguments': [] };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      } else {
        let msg = 'Bu işlemi gerçekleştirmeye yetkiniz yok';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Ziyaret edilen hastaları temizleme işlemi hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_cleanVisitedPatientListConfirmed: function () {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Kayıtlı hasta listesi temizleniyor' } });
      UserService.clean_visited_patients({ 'username': this.user_data.username })
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
        });
    },
    f_showUserCloneModal: function () {
      if (this.is_edit) {
        let msg = 'Kullanıcı düzenlerken başka hesabı kopyalayamazsınız';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Kullanıcı kopyalama işlemi hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      } else {
        if (this.d_availableUserListForClone.length === 0) {
          let data = {};
          data.active_status = 'active'; // 'active, 'passive', 'all'
          data.forbidden_perms = ['wisdom', 'administrator']; // array
          data.forbidden_usernames = []; // array
          data.needed_perms = []; // array
          data.needed_perm_type = 'or'; // 'or', 'and'
          UserService.get_users_with_filter(data)
            .then(resp => {
              for (let i in resp.data.result) {
                this.d_availableUserListForClone.push({ 'value': resp.data.result[i].username, 'label': resp.data.result[i].first_name + ' ' + resp.data.result[i].last_name });
              }
              this.d_showUserCloneModal = true;
            });
        } else {
          this.d_showUserCloneModal = true;
        }
      }
    },
    f_cloneFromAnotherUser: function () {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Kullanıcı bilgisi getiriliyor. Lütfen bekleyiniz' } });
      UserService.get_user_for_cloning({ 'username': this.d_userCloneSource.value })
        .then(resp => {
          this.user_data = resp.data.result;
          if (!this.user_data.permissions_result) {
            this.user_data.permissions_result = [];
          }
          this.$forceUpdate();
          this.d_showUserCloneModal = false;
          // this.user_data = resp.data;
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          // this.get_hospital_group();
        });
    },
    f_getProfilePicture: function () {
      GlobalService.get_one_cb_document({ 'key': 'user_' + this.user_data.username + '_picture', 'bucket': 'uploads' })
        .then(resp => {
          if (resp.data.status === 'success') {
            this.d_userProfilePicture = resp.data.result.picture;
          }
        });
    },
    f_uploadProfilePicture: function () {
      UserService.upload_profile_picture({ 'username': this.user_data.username, 'picture': this.d_uploadPicData })
        .then(resp => {
          if (resp.data.status === 'success') {
            this.d_showProfilePictureModal = false;
          }
        });
    },
    f_createAcceptedDocumentTypes: function () {
      let accept = '';
      let file_types = ["jpg", "jpeg", "png", "docx", "doc", "pdf"];
      for (let i in file_types) {
        accept += '.' + file_types[i] + ',';
      }
      return accept;
    },
    f_addDocument: function () {
      let arr = this.d_tmpFile.name.split('.');
      let fileExtension = arr[arr.length - 1];
      if (["jpg", "jpeg", "png", "bmp"].indexOf(fileExtension) === -1) {
        this.d_tmpFile = '';
        this.$forceUpdate();
        alert('yasaklı dosya tipi');
        return;
      }
      if (this.d_tmpFile.size > 262144) {
        this.d_tmpFile = '';
        this.$forceUpdate();
        alert('Dosya boyutu 256kb den fazla olamaz');
        return;
      }

      var reader = new FileReader();
      var vm = this;
      reader.addEventListener("load", function () {
        vm.d_uploadPicData = reader.result;
      }, false);
      reader.readAsDataURL(this.d_tmpFile);
      this.d_tmpFile = '';
    },
    fAddLocation: function () {
      alert('geliştirme sürecinde');
    },
    f_showTrueFalse: function () {
      this.d_showPage = false;
      setTimeout(function () {
        this.d_showPage = true;
      }.bind(this), 1000)
    },
    f_giveThisWisdomDataModelPermissions: function (type) {
      this.show_module_and_model = false;
      let module_list = JSON.parse(localStorage.getItem('modules'));
      let models = {};
      this.f_getModelsFromModuleList(module_list['list'], models);
      if (type === 'equal') {
        // superuserın silebileceği data modellerini siliyoruz.
        for (let m in models) {
          if (models[m] && models[m].permission_by_superuser === 'yes' && models[m].permissions_list_for_superuser && models[m].permissions_list_for_superuser.length > 0) {
            if (this.user_data['position_department_title'][this.selected_pdt]['wisdom_data_model'][m]) {
              let special_to_user_perms = [];
              for (let p in this.user_data['position_department_title'][this.selected_pdt]['wisdom_data_model'][m].permission) {
                let user_perm = this.user_data['position_department_title'][this.selected_pdt]['wisdom_data_model'][m].permission[p];
                if (models[m].permissions_list_for_superuser.indexOf(user_perm) === -1) {
                  special_to_user_perms.push(user_perm);
                }
              }
              if (special_to_user_perms.length === 0) {
                this.$delete(this.user_data['position_department_title'][this.selected_pdt]['wisdom_data_model'], m);
              } else {
                this.user_data['position_department_title'][this.selected_pdt]['wisdom_data_model'][m].permission = special_to_user_perms;
              }
            }
          }
        }
      }
      if (this.selected_permission_from_user) {
        for (let m in this.selected_permission_from_user.pdt.wisdom_data_model) {
          if (models[m] && models[m].permission_by_superuser === 'yes' && models[m].permissions_list_for_superuser && models[m].permissions_list_for_superuser.length > 0) {
            if (!this.user_data['position_department_title'][this.selected_pdt]['wisdom_data_model'][m]) {
              this.user_data['position_department_title'][this.selected_pdt]['wisdom_data_model'][m] = {
                'data_type': m,
                'name': models[m].name,
                'permission': []
              };
            }
            for (let p_ind in this.selected_permission_from_user.pdt.wisdom_data_model[m].permission) {
              this.user_data['position_department_title'][this.selected_pdt]['wisdom_data_model'][m].owner_type = this.selected_permission_from_user.pdt.wisdom_data_model[m].owner_type;
              let perm = this.selected_permission_from_user.pdt.wisdom_data_model[m].permission[p_ind];
              if (models[m].permissions_list_for_superuser.indexOf(perm) !== -1) {
                if (this.user_data['position_department_title'][this.selected_pdt]['wisdom_data_model'][m].permission.indexOf(perm) === -1) {
                  this.user_data['position_department_title'][this.selected_pdt]['wisdom_data_model'][m].permission.push(perm);
                }
              }
            }
          }
        }
      }
      setTimeout(function () {
        this.show_module_and_model = true;
      }.bind(this), 1000)
      this.$forceUpdate();
    },
    f_getModelsFromModuleList: function (data_list, models) {
      for (let i in data_list) {
        if (data_list[i].is_model) {
          models[data_list[i].value] = data_list[i];
        }
        if (data_list[i].list && data_list[i].list.length > 0) {
          this.f_getModelsFromModuleList(data_list[i].list, models);
        }
      }
    },
    fActivityChange: function (pdt_ind) {
      this.user_data.position_department_title[pdt_ind].active = 1;
      for (let i in this.user_data.position_department_title) {
        if (parseInt(i) !== pdt_ind) {
          this.user_data.position_department_title[i].active = 0;
        }
      }
      this.$forceUpdate();
    },
    fUpdatePositionDepartmentTitle: function () {
      for (let d in this.tree_list.department.selected_items_data_list) {
        let dep_data = this.tree_list.department.selected_items_data_list[d];
        for (let p in this.tree_list.position.selected_items_data_list) {
          let pos_data = this.tree_list.position.selected_items_data_list[p];
          for (let ut in this.tree_list.user_title.selected_items_data_list) {
            let ut_data = this.tree_list.user_title.selected_items_data_list[ut];
            if (dep_data.isTitle === 'yes' || pos_data.isTitle === 'yes' || ut_data.isTitle === 'yes') {
              alert('Gruplama başlığı olan seçenekleri kullanmayınız.');
              return;
            }
            this.user_data.position_department_title[this.selected_pdt_index - 1].position = pos_data;
            this.user_data.position_department_title[this.selected_pdt_index - 1].department = dep_data;
            this.user_data.position_department_title[this.selected_pdt_index - 1].title = ut_data;
          }
        }
      }
      this.$forceUpdate();
    },
    fAddPositionDepartmentTitle: function () {
      if (!this.user_data.position_department_title) {
        this.user_data.position_department_title = [];
      }
      for (let d in this.tree_list.department.selected_items_data_list) {
        let dep_data = this.tree_list.department.selected_items_data_list[d];
        for (let p in this.tree_list.position.selected_items_data_list) {
          let pos_data = this.tree_list.position.selected_items_data_list[p];
          for (let ut in this.tree_list.user_title.selected_items_data_list) {
            let ut_data = this.tree_list.user_title.selected_items_data_list[ut];
            if (dep_data.isTitle === 'yes' || pos_data.isTitle === 'yes' || ut_data.isTitle === 'yes') {
              alert('Gruplama başlığı olan seçenekleri kullanmayınız.');
              return;
            }
            let det = false;
            for (let pdt in this.user_data.position_department_title) {
              let pdt_data = this.user_data.position_department_title[pdt];
              if (dep_data.value === pdt_data.department.value && pos_data.value === pdt_data.position.value && ut_data.value === pdt_data.title.value) {
                det = true;
                break;
              }
            }
            if (!det) {
              let active = 0;
              if (this.user_data.position_department_title.length === 0) {
                active = 1;
              }
              this.$delete(pos_data, 'isTitle');
              this.$delete(dep_data, 'isTitle');
              this.$delete(ut_data, 'isTitle');
              let x = { 'permission': [], 'active': active, 'position': pos_data, 'department': dep_data, 'title': ut_data, 'wisdom_data_model': {} };
              this.user_data.position_department_title.push(x);
            }
          }
        }
      }
      this.$forceUpdate();
    },
    fAddClinicalBranchs: function () {
      if (!this.user_data.clinical_branch) {
        this.user_data.clinical_branch = [];
      }
      let str = '';
      for (let k in this.tree_list.clinical_branch.selected_items_data_list) {
        if (this.tree_list.clinical_branch.selected_items_data_list[k].isTitle === 'yes') {
          str += ' * ' + this.tree_list.clinical_branch.selected_items_data_list[k].label + '\n';
          continue;
        }
        let det = false;
        for (let i in this.user_data.clinical_branch) {
          if (this.user_data.clinical_branch[i].value === this.tree_list.clinical_branch.selected_items_data_list[k].value) {
            det = true;
            break;
          }
        }
        if (!det) {
          let cl_branch = JSON.parse(JSON.stringify(this.tree_list.clinical_branch.selected_items_data_list[k]));
          if (!cl_branch.hims_branch_id) {
            alert('Lütfen HBYS branş IDsini hastane ayarlarından önce tanımlayınız.');
          } else {
            this.$delete(cl_branch, 'list');
            this.user_data.clinical_branch.push(cl_branch);
          }
        }
      }
      this.$forceUpdate();
      if (str) {
        alert(str + '\n Seçenekleri gruplandırma başlığı olarak kullanıldığından eklenmesi uygun değildir.');
      }
    },
    f_resetForm: function () {
      this.user_data = JSON.parse(JSON.stringify(this.user_data_bck));
      this.f_showTrueFalse();
    },
    get_permission_scheme: function () {
      PermissionShemeService.get_permission_scheme()
        .then(resp => {
          if (resp.data.status === 'success') {
            this.permission_scheme = resp.data.result;
          }
        });
    },
    GetThisUserInformationToSelectedUser: function (data) {
      if (!this.user_data[this.user_data.hospital_group]) {
        this.user_data[this.user_data.hospital_group] = {};
      }
      if (!this.user_data[this.user_data.hospital_group][this.user_data.hospital_id]) {
        this.user_data[this.user_data.hospital_group][this.user_data.hospital_id] = {};
      }
      this.user_data[this.user_data.hospital_group][this.user_data.hospital_id].personnelId = data.item.personnel_id;
      // aktif personel id sini atıyoruz. Ayrıca ilgili hastane grubunda da hastanın datasını oluşturuyoruz
      // böylece kullanıcı başka bir hastaneye gittiğinde yeni personel id'sini atayabiliriz.
      this.user_data.personnelId = data.item.personnel_id;
      this.$forceUpdate();
    },
    getPersonelIdWithHimsUserName: function () {
      // thisi function is not active
      let query = 'hospitalGroup=' + this.hospital_data.hospital_group + '&' + 'hospitalCode=' + this.hospital_data.hospital_id;
      WebService.getPersonelIdWithHimsUserName(query)
        .then(resp => {
          if (resp.data.status === 'success') {
            this.himsUserList = resp.data.result;
          } else {
            this.himsUserList = [];
          }
        });
    },
    CancelSettings: function (parent, subparent = '') {
      if (subparent) {
        if (this.user_data.settings && this.user_data.settings[parent] && this.user_data.settings[parent].settings[subparent]) {
          this.user_data.settings[parent].settings[subparent]['use'] = '0';
        }
      } else {
        if (this.user_data.settings && this.user_data.settings[parent]) {
          this.user_data.settings[parent]['use'] = '0';
        }
      }
      this.$forceUpdate();
    },
    ActivateSettings: function (parent, subparent = '') {
      if (subparent) {
        if (this.user_data.settings && this.user_data.settings[parent] && this.user_data.settings[parent].settings[subparent]) {
          this.user_data.settings[parent].settings[subparent]['use'] = '1';
        } else {
          if (!this.user_data.settings) {
            this.user_data.settings = {};
          }
          if (!this.user_data.settings[parent]) {
            for (let i in this.user_settings_list) {
              if (this.user_settings_list[i].value === parent) {
                this.user_data.settings[parent] = { 'name': { 'label': this.user_settings_list[i].label, 'translation': this.user_settings_list[i].translation }, 'use': '1', 'settings': {} };
                break;
              }
            }
          }
          if (!this.user_data.settings[parent].settings[subparent]) {
            let data = { 'type': parent, 'sub_type_list': [] }
            data['sub_type_list'].push(subparent);
            OptionService.get_user_sub_settings(data)
              .then(resp => {
                this.user_data.settings[parent].settings[subparent] = resp.data.results[subparent];
                this.$forceUpdate();
              });
          }
        }
      } else {
        if (this.user_data.settings && this.user_data.settings[parent]) {
          this.user_data.settings[parent]['use'] = '1';
        } else {
          if (!this.user_data.settings) {
            this.user_data.settings = {};
          }
          if (!this.user_data.settings[parent]) {
            for (let i in this.user_settings_list) {
              if (this.user_settings_list[i].value === parent) {
                this.user_data.settings[parent] = { 'name': { 'label': this.user_settings_list[i].label, 'translation': this.user_settings_list[i].translation }, 'use': '1', 'settings': {} };
                break;
              }
            }
          }
        }
      }
      this.$forceUpdate();
    },
    GetUserSettingsList: function () {
      OptionService.get_user_settings_list()
        .then(resp => {
          this.user_settings_list = resp.data.results;
        });
    },
    UseFunc: function (data) {
      if (data.use === 1) {
        data.use = 0;
      } else {
        data.use = 1;
      }
      this.$forceUpdate();
    },
    CreateSettings: function () {
      this.user_data.settings = {};
      if (!this.user_data.settings.oncologyModule) {
        this.user_data.settings.oncologyModule = {
          'name': {
            'label': 'Onkoloji Modülü Ayarları',
            'translation': { 'tr': 'Onkoloji Modülü Ayarları', 'en': 'Oncology Module Settings' }
          },
          'settings': {
            'drugApprovalDosage': {
              'name': {
                'label': 'İlaç Onay Dozu',
                'translation': { 'tr': 'İlaç Onay Dozu', 'en': 'Drug Approval Dosage' }
              },
              'drugs': {}
            }
          }
        }
      }
    },
    AddDrugToDosageSettings: function () {
      if (!this.user_data.settings) {
        this.CreateSettings();
      }
      if (!this.user_data.settings.oncologyModule.settings.drugApprovalDosage.drugs[this.SelectedDrug.value]) {
        this.user_data.settings.oncologyModule.settings.drugApprovalDosage.drugs[this.SelectedDrug.value] = {
          'name': this.SelectedDrug,
          'max': { 'use': '1', 'val': '0' },
          'min': { 'use': '1', 'val': '0' },
        }
        this.$forceUpdate();
      } else {
        alert('İlaç eklidir.');
      }
    },
    drug_search: function (search, loading) {
      if (this.search_all_drugs === true) {
        if (search && search.length > 2) {
          loading(true);
          let data = { 'search': search, 'wdm_type': 'wdm2' };
          OptionService.search_wdm_option(data)
            .then(resp => {
              if (resp.data.status === 'success') {
                this.drug_search_list = resp.data.result;
              } else {
                this.drug_search_list = [];
              }
              loading(false);
            });
        }
      }
    },
    CalculateNavClass: function (type) {
      if (this.SelectedNavItem === type) {
        return 'nav-item-selected';
      } else {
        return 'nav-item-not-selected';
      }
    },
    get_all_permissions: function () {
      PermissionService.get_permission_tree()
        .then(resp => {
          if (resp.data.status === 'success') {
            this.all_permissions_data = resp.data.result;
          }
        });
    },
    toLowerCase (value, event) {
      return value.toLowerCase()
    },
    get_hospital_group: function () {
      HospitalService.get_all_hospitals_list()
        .then(resp => {
          if (resp.data.status === 'success') {
            this.hospital_group_data = resp.data.result;
            if (this.is_edit) {
              this.old_username = this.edit_user_data.username;
              if (this.edit_user_data.hospital_group) {
                for (let g in this.hospital_group_data) {
                  if (this.hospital_group_data[g].value === this.edit_user_data.hospital_group) {
                    this.selected_hospital_group = this.hospital_group_data[g];
                  }
                }
              }
              this.user_data = JSON.parse(JSON.stringify(this.edit_user_data));
              this.user_data_bck = JSON.parse(JSON.stringify(this.user_data));
              if (this.user_data.permissions_result.indexOf('wisdom') !== -1) {
                this.wisdom_user = true;
              }
            } else {
              for (let g in this.hospital_group_data) {
                if (this.hospital_group_data[g].value === this.user.hospital_group) {
                  this.selected_hospital_group = this.hospital_group_data[g];
                }
              }
              this.user_data.hospital_group = this.user.hospital_group;
              this.user_data.hospital_id = this.user.hospital_id;
            }
            // aktif departmanın indexini seçiyoruz.
            if (this.user_data.position_department_title && this.user_data.position_department_title.length > 0) {
              for (let i in this.user_data.position_department_title) {
                if (this.user_data.position_department_title[i].active === 1) {
                  this.selected_pdt = i;
                  break;
                }
              }
            }
            if (this.user_data && this.user_data.wdm_department) {
              this.tree_list.wdm_department.selected_items_data_list = JSON.parse(JSON.stringify(this.user_data.wdm_department.list));
            }
          } else {
            alert('Grup kaydı henüz yok.');
          }
          this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
        });
    },
    select_permission_scheme: function (type) {
      if (type === 'equal') {
        let is_wisdom = false;
        let is_admin = false;
        if (this.user_data.position_department_title[this.selected_pdt].permission.indexOf('wisdom') !== -1) {
          is_wisdom = true;
        }
        if (this.user_data.position_department_title[this.selected_pdt].permission.indexOf('administrator') !== -1) {
          is_admin = true;
        }
        this.user_data.position_department_title[this.selected_pdt].permission = this.permission_scheme.scheme[this.selected_permission_scheme].permission;
        if (is_wisdom) {
          this.user_data.position_department_title[this.selected_pdt].permission.push('wisdom');
        }
        if (is_admin) {
          this.user_data.position_department_title[this.selected_pdt].permission.push('administrator');
        }
      } else if (type === 'add_new_ones') {
        for (let i in this.permission_scheme.scheme[this.selected_permission_scheme].permission) {
          let perm = this.permission_scheme.scheme[this.selected_permission_scheme].permission[i];
          if (this.user_data.position_department_title[this.selected_pdt].permission.indexOf(perm) === -1) {
            this.user_data.position_department_title[this.selected_pdt].permission.push(perm);
          }
        }
      }
    },
    select_permission_from_user: function (type) {
      let perms = this.selected_permission_from_user.pdt.permission;
      if (perms.indexOf('wisdom') !== -1) {
        perms.splice(perms.indexOf('wisdom'), 1);
      }
      if (perms.indexOf('administrator') !== -1) {
        perms.splice(perms.indexOf('administrator'), 1);
      }
      if (type === 'equal') {
        let is_wisdom = false;
        let is_admin = false;
        if (this.user_data.position_department_title[this.selected_pdt].permission.indexOf('wisdom') !== -1) {
          is_wisdom = true;
        }
        if (this.user_data.position_department_title[this.selected_pdt].permission.indexOf('administrator') !== -1) {
          is_admin = true;
        }
        this.user_data.position_department_title[this.selected_pdt].permission = perms;
        if (is_wisdom) {
          this.user_data.position_department_title[this.selected_pdt].permission.push('wisdom');
        }
        if (is_admin) {
          this.user_data.position_department_title[this.selected_pdt].permission.push('administrator');
        }
      } else if (type === 'add_new_ones') {
        for (let i in perms) {
          if (this.user_data.position_department_title[this.selected_pdt].permission.indexOf(perms[i]) === -1) {
            this.user_data.position_department_title[this.selected_pdt].permission.push(perms[i]);
          }
        }
      }
      this.$forceUpdate();
    },
    select_group: function (item) {
      let is_wisdom = false;
      let is_admin = false;

      if (this.user_data.permissions_result.indexOf('wisdom') !== -1) {
        is_wisdom = true;
      }
      if (this.user_data.permissions_result.indexOf('administrator') !== -1) {
        is_admin = true;
      }

      this.user_data.permissions_result = [];

      if (is_wisdom) {
        this.user_data.permissions_result.push('wisdom');
      }
      if (is_admin) {
        this.user_data.permissions_result.push('administrator');
      }

      for (let i_1 in this.all_permissions_data.list) {
        let list_1 = this.all_permissions_data.list[i_1];
        for (let p_1 in list_1.permission) {
          if (list_1.permission[p_1].default.indexOf(item) !== -1) {
            this.user_data.permissions_result.push(list_1.permission[p_1]['value']);
          }
        }
        for (let i_2 in list_1.list) {
          let list_2 = list_1.list[i_2];
          for (let p_2 in list_2.permission) {
            if (list_2.permission[p_2].default.indexOf(item) !== -1) {
              this.user_data.permissions_result.push(list_2.permission[p_2]['value']);
            }
          }
          for (let i_3 in list_2.list) {
            let list_3 = list_2.list[i_3];
            for (let p_3 in list_3.permission) {
              if (list_3.permission[p_3].default.indexOf(item) !== -1) {
                this.user_data.permissions_result.push(list_3.permission[p_3]['value']);
              }
            }
          }
        }
      }
    },
    select_department: function (item) {
      this.user_data.department = item;
    },
    f_saveUser: function () {
      let email_regex = this.f_emailRegexCheck();
      if (!email_regex.status) {
        alert('Geçerli bir email adresi girilmesi gerekmektedir.');
        return;
      } else {
        // this.user_data.email = email_regex.value;
      }
      if (!this.user_data.position_department_title || this.user_data.position_department_title.length === 0) {
        alert('Görev & Departmanı & Ünvan tanımı olmayan kullanıcı kaydedilemez.');
        return;
      }
      if (!this.user_data.email || this.user_data.email.length === 0 || this.user_data.email === '') {
        alert('Email adresi boş olarak geçilemez');
        return;
      }
      /*this.user_data.department = this.selected_department.value;*/
      /*this.user_data.hospital_group = this.selected_hospital_group.value;
      this.user_data.hospital_id = this.selected_hospital.value;*/
      if (this.is_edit) {
        this.user_data.old_username = this.old_username;
        if (!this.changePassword) {
          delete this.user_data.password;
        }
      }
      /*
      for (let i in this.selected_hospital_group.hospital_list) {
        if (this.user_data.hospital_id === this.selected_hospital_group.hospital_list[i].value) {
          this.user_data.HospitalID = this.selected_hospital_group.hospital_list[i].HospitalID;
          break;
        }
      }
      */
      if (!this.user_data.wdm_department) {
        this.user_data.wdm_department = { 'list': [] };
      }
      if (this.tree_list.wdm_department.selected_items_data_list) {
        this.user_data.wdm_department.list = JSON.parse(JSON.stringify(this.tree_list.wdm_department.selected_items_data_list));
      }
      let current_account_list = JSON.parse(localStorage.getItem('account_list'));
      UserService.save(this.user_data)
        .then(resp => {
          if (resp.data.status === 'success') {
            if (current_account_list[0].username === this.user.username && this.user.username === this.user_data.username) {
              let special_permission = [];
              if (this.user.permissions_result.indexOf('wisdom_login-to-users-without-password') !== -1) {
                special_permission.push('wisdom_login-to-users-without-password');
              }
              let main_account_data = { 'main_account': 1, 'name': this.user.first_name + ' ' + this.user.last_name, 'user_id': 'user_' + this.user.username, 'username': this.user.username, 'password': this.user.password, 'special_permission': special_permission };
              let new_account_list = [];
              new_account_list.push(main_account_data);
              for (let k in this.user_data.account_list) {
                new_account_list.push(this.user_data.account_list[k]);
              }
              localStorage.setItem('account_list', JSON.stringify(new_account_list));
              this.$store.commit('account_list', new_account_list);
            }
            this.$router.push('/dashboard');
          } else {
            alert(resp.data.message);
          }
        });
    },
    // GetClinicalBranchesOfHospital: function () {
    //   let query = 'HospitalGroup=' + this.user_data.hospital_group + '&' + 'hospitalCode=' + this.user_data.hospital_id;
    //   WebService.get_clinical_branches_of_hospital(query)
    //     .then(resp => {
    //       this.HospitalClinicalBranchResult = resp.data;
    //     });
    // },
    f_prepareTreeListSelectedItems: function (tree_list_type) {
      this.tree_list[tree_list_type].selected_items_data_list = [];
      if (tree_list_type === 'module_and_model') {
        for (let m in this.user_data['position_department_title'][this.selected_pdt]['wisdom_data_model']) {
          let data = this.user_data['position_department_title'][this.selected_pdt]['wisdom_data_model'][m];
          this.tree_list[tree_list_type].selected_items_data_list.push({ 'label': data['name']['label'], 'value': data['data_type'] });
        }
      }
    },
    f_changeHospitalGroup: function () {
      if (this.user_data.hospital_group) {
        for (let g in this.hospital_group_data) {
          if (this.hospital_group_data[g].value === this.user_data.hospital_group) {
            this.selected_hospital_group = this.hospital_group_data[g];
            break;
          }
        }
        this.$forceUpdate();
      }
    }
  },
  watch: {
    'StoreModal.show': function () {
      if (!this.StoreModal.show && this.StoreModal.data.function_name) {
        if (this.StoreModal.data.function_name === 'f_cleanVisitedPatientListConfirmed') {
          if (this.StoreModal.data.confirm) {
            this.f_cleanVisitedPatientListConfirmed();
          }
        }
      }
    },
    'changePassword': function () {
      this.f_checkPasswordFilled();
      if (this.changePassword) {
        this.user_data.password = '';
      }
    },
    'd_showProfilePictureModal': function () {
      if (this.d_showProfilePictureModal) {
        this.f_getProfilePicture();
      }
    },
    'selected_pdt': function () {
      if (this.selected_pdt && this.SelectedNavItem === 'nav-permission-wisdomdatamodel') {
        this.f_prepareTreeListSelectedItems('module_and_model');
      }
    },
    'SelectedNavItem': function () {
      if (this.SelectedNavItem === 'nav-permission' || this.SelectedNavItem === 'nav-permission-wisdomdatamodel') {
        UserService.getAllUsersPermissionsWithDepartments()
          .then(resp => {
            if (resp.data.status === 'success') {
              for (let i in resp.data.result) {
                if (this.user_data.username !== resp.data.result[i].value) {
                  this.user_list_with_departments.push(resp.data.result[i]);
                }
              }
            }
          });
      } else if (this.SelectedNavItem === 'nav-permission-wisdomdatamodel') {
        this.f_prepareTreeListSelectedItems('module_and_model');
      }
    },
    'search_textxxxxxxxxxxxx': function () {
      this.himsUserListAfterSearched = [];
      if (this.search_text) {
        let txt = this.search_text;
        for (let i in this.himsUserList) {
          if (this.himsUserList[i].personnel_id && this.himsUserList[i].resource_id && this.himsUserList[i].resource_name && this.himsUserList[i].group_name && this.himsUserList[i].username) {
            if (this.himsUserList[i].personnel_id.toString().indexOf(txt) !== -1 || this.himsUserList[i].resource_id.toString().indexOf(txt) !== -1 || this.himsUserList[i].resource_name.indexOf(txt) !== -1 || this.himsUserList[i].username.indexOf(txt) !== -1 || this.himsUserList[i].group_name.indexOf(txt) !== -1) {
              this.himsUserListAfterSearched.push(this.himsUserList[i]);
            }
          }
        }
      } else {
        this.himsUserListAfterSearched = JSON.parse(JSON.stringify(this.himsUserList));
      }
    },
    // 'user_data.hospital_id': function () {
    //   if (this.user_data.hospital_id && this.user_data.hospital_group) {
    //     this.GetClinicalBranchesOfHospital();
    //   }
    // },
    'user_data.hospital_group': function () {
      if (this.user_data.hospital_group) {
        for (let g in this.hospital_group_data) {
          if (this.hospital_group_data[g].value === this.user_data.hospital_group) {
            this.selected_hospital_group = this.hospital_group_data[g];
            break;
          }
        }
        this.$forceUpdate();
      }
    }
  },
  components: {
    vSelect,
    cSwitch,
    WTopMenu,
    UserWdm248Management,
    UserAccountList,
    TreeList,
    Modal
  }
};

</script>

<style type="text/css">
.nav-item-selected {
  background: #d6f2b0;
}

.nav-item-not-selected {}

</style>

